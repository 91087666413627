/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/scss/bootstrap";

* {
    font-family: "Poppins", sans-serif;
}

body {
    overflow-x: hidden !important;
}

.modal-open .modal {
    background: rgba(0,0,0,0.5);
}

.primary-color { color: #ea8c33 !important; }
.theme-color { color: #418b32 !important; }

.dark-bl {
    color: #000;
}
.f-b {
    font-weight: bold;
}
.title {
    font-size: 16px;
    margin-bottom: 20px;
}
.light-bl {
    color: #7fb748;
}
.f-b {
    font-weight: bold;
}
.m-10 {
    margin: 10px !important;
}
.modal-backdrop.show {
    opacity: 0 !important;
}
.restarentTitles {
    font-size: 28px;
    font-family: CoreSans, sans-serif;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: 0;
    margin: 12px 0px;
}
// .font14ColorCode {color:#a7a7a7}
.font24 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 1rem;
}
.paddT5rem {
    padding-top: 4rem;
}

.font16 {
    font-size: 18px;
    font-weight: bold;
}
.font14 {
    font-size: 14px;
    // font-weight: 600;
}
.font13 {
    font-size: 12px;
}
.cursorPoin {
    cursor: pointer !important;
}
.was-validated .form-control:invalid,
.form-control.is-invalid {
    background-image: none !important;
}

// add new
.max-w-100 {
    max-width: 100%;
}
.MuiTypography-subtitle1 {
    color: #a7a7a7;
    font-size: 14px;
    // font-weight: 500;
    line-height: 22px;
    letter-spacing: 0;
    font-weight: 300;
}

.MuiTypography-colorTextPrimary {
    color: #162028 !important;
}

.careers_info {
    color: #fff;
    padding-right: 20px;
    float: left;
    color: #fff;
    font-size: 12px;
    cursor: pointer;
    &:hover {
        color: #aaa;
    }
}
.MuiTypography-h2 {
    font-size: 30px;
    font-weight: 500;
    line-height: 1.3;
    letter-spacing: 0.5px;
    margin-bottom: 24px;
}

// footer
.footer_info {
    color: white;
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    line-height: 21px;
    text-transform: uppercase;
}
.privacy_info {
    color: #fff;
    font-size: 12px;
    float: left;
    padding-right: 2.5rem !important;
    padding: 20px 0px 20px 0px;
    cursor: pointer;
    &:hover {
        color: #aaa;
    }
}
.MuiTypography-body1 {
    color: #5e6b77;
    font-size: 14px;
    font-family: CoreSans, sans-serif;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0.00938em;
}
.pagination {
    .page-item {
        &.active {
            .page-link {
                z-index: 3;
                color: #fff;
                background-color: transparent !important;
                border: none;
                color: #000;
                border-top: 3px solid #ec8b23 !important;
            }
        }
        .page-link {
            z-index: 3;
            color: #fff;
            background-color: transparent !important;
            border: none;
            color: #000;
            border-top: 3px solid transparent !important;
        }
    }
    .page-item.active .page-link {
        
    }
    .page-item.disabled .page-link {
        display: none !important;
    }
}

.paymentTitles {
    font-size: 20px;

    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0;
    color: #162028;
    padding: 16px;
}

.form-control {
    font-size: 14px !important;
}
.jWAJib {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 21px;
}

.applyCupons {
    color: #418b32;
    font-size: 16px;
    font-weight: 600;
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.header-subtitle2 {
    font-size: 14px;
    max-width: 100%;
    color: rgb(12, 21, 29);
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0;
}
.hintPlaceholder {
    font-size: 20px;
    font-weight: 300;
    color: #999;
    padding: 20px 10px;
}
.hintLabel {
    font-size: 12px;
    font-weight: 300;
    color: #999;
}
@media (max-width: 768px) {
    .careers_info {
        color: #fff;
        padding-right: 20px;
        float: left;
        color: #fff;
        font-size: 12px;
        width: 50%;
        padding-bottom: 10px;
    }
    .nopadding {
        padding: 16px !important;
    }
    .privacy_info {
        float: left;
        color: #fff;
        font-size: 12px;
        width: 50%;
        padding-bottom: 10px !important;
        padding: 0px;
        text-align: left;
        padding-right: 0px !important;
    }
    .MuiTypography-h2 {
        margin-bottom: 0px;
    }
}

// CUSTOM MODAL
.modal-close-outside {
    position: absolute;
    z-index: 1000;
    left: -46px;
    background: red;
    top: 40px;
    cursor: pointer;
    border-radius: 10px;
    &:hover {
        background: rgb(155, 2, 2);
    }
    .close {
        font-size: 30px;
        font-weight: 200;
        padding: 5px 12px;
        color: white !important;
        opacity: 1;
    }
}

.authPopup {
    padding: 2rem !important;
    background-image: url('assets/images/section-2-bg.png');
    background-repeat: no-repeat;
    background-size: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    form {
        width: 340px;
        .logoBlock {
            text-align: center;
            margin-bottom: 20px;
            img {
                width: 80px;
            }
        }
        .form-group {
            label {
                color: #777;
                font-size: 12px;
                margin-bottom: 2px;
            }
            button {
                font-size: 14px;
            }
        }
    }
    
}
.cursorPoin {
    color: white;
    &:hover {
        color: #e16600;
    }
    &.forgot {
        color: black;
    }
}
.modal.left .modal-dialog,
	.modal.right .modal-dialog {
		position: fixed;
		margin: auto;
		width: 40%;
        max-width: 40%;
		height: 100%;
		-webkit-transform: translate3d(0%, 0, 0);
		    -ms-transform: translate3d(0%, 0, 0);
		     -o-transform: translate3d(0%, 0, 0);
		        transform: translate3d(0%, 0, 0);
	}

	.modal.left .modal-content,
	.modal.right .modal-content {
		height: 100%;
		overflow-y: auto;
	}
	
	.modal.left .modal-body,
	.modal.right .modal-body {
		padding: 15px 15px 80px;
	}

/*Left*/
	.modal.left.fade .modal-dialog{
		left: -320px;
		-webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
		   -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
		     -o-transition: opacity 0.3s linear, left 0.3s ease-out;
		        transition: opacity 0.3s linear, left 0.3s ease-out;
	}
	
	.modal.left.fade.in .modal-dialog{
		left: 0;
	}
        
/*Right*/
	.modal.right.fade .modal-dialog {
		right: 0;
		-webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
		   -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
		     -o-transition: opacity 0.3s linear, right 0.3s ease-out;
		        transition: opacity 0.3s linear, right 0.3s ease-out;
	}
	
	.modal.right.fade.in .modal-dialog {
		right: 0;
	}

/* ----- MODAL STYLE ----- */
	.modal-content {
		border-radius: 0;
		border: none;
	}

	.modal-header {
		border-bottom-color: #EEEEEE;
		background-color: #FAFAFA;
	}
@media (max-width: 768px) {
    .modal.left .modal-dialog,
    .modal.right .modal-dialog {
        width: 85% !important;
        max-width: 85% !important;
    }
    .modal-close-outside {
        top: 20px;
    }
}
.modal-close-inside {
    .closeButton {
        background: white;
        padding: 10px;
        border-radius: 100%;
        margin-top: 10px;
        margin-left: 10px;
        border:none;
        &:hover {
            background: #eee;
        }
    }
}
.f12lightgray {
    font-size: 12px;
    color: #999;
}

.otp-input{
    width: 50px !important;
    height: 50px !important;
    border-radius: 10px !important;
    border: 1px solid #c5c5c5;
    text-align: center;
    font-size: 32px !important;
}
.noteText {
    color: #777;
    font-size: 12px;
    line-height: 1;
    padding-left: 10px;
}